import React from 'react';

// SOCIAL

export const SOCIAL_FACEBOOK = (
  <g>
    <path
      d="M34.3,11.1h9V0.5h-9c-6.9,0-12.4,5.5-12.4,12.4v5.3h-7.1V29h7.1v28.5h10.6V29h9l1.8-10.6H32.7v-5.3C32.7,12.1,33.5,11.1,34.3,11.1z"
    />
  </g>
);

export const SOCIAL_TWITTER = (
  <g>
    <path
      d="M57.5,10.8c-2.2,1-4.3,1.7-6.7,1.9c2.4-1.4,4.3-3.8,5.3-6.7c-2.4,1.4-4.8,2.4-7.4,2.9c-2.2-2.4-5.3-3.8-8.6-3.8
      c-6.5,0-11.7,5.5-11.7,12c0,1,0,1.9,0.2,2.6C18.7,19.2,10.1,14.4,4.3,7c-1,1.7-1.7,3.8-1.7,6c0,4.1,2.2,7.9,5.3,10.1
      c-1.9,0-3.8-0.7-5.3-1.4v0.2c0,5.7,4.1,10.8,9.6,12c-1,0.2-1.9,0.5-3.1,0.5c-0.7,0-1.4,0-2.2-0.2c1.4,4.8,5.7,8.4,11,8.4
      c-4.1,3.4-9.1,5.3-14.6,5.3c-1,0-1.9,0-2.9-0.2c5.3,3.4,11.5,5.5,18.2,5.5c21.8,0,33.5-18.4,33.5-34.5v-1.7
      C54.1,15.3,56.1,13.2,57.5,10.8z"
    />
  </g>
);

export const SOCIAL_LINKEDIN = (
  <g>
    <path
      d="M21.1,18.8h11.1v5.7h0.2c1.7-2.8,5.4-5.7,11.1-5.7c11.8,0,14,7.3,14,17v19.6H45.9V38c0-4,0-9.5-6.1-9.5
      s-7.1,4.5-7.1,9.2v17.5H21.1V18.8z M0.7,18.8h12.1v36.4H0.7V18.8z M12.8,8.7c0,3.3-2.8,6.1-6.1,6.1S0.5,12,0.5,8.7s2.8-6.1,6.1-6.1
      S12.8,5.3,12.8,8.7z"
    />
  </g>
);

export const SOCIAL_VODACOM = (
  <g>
    <path
      d="M29,0.5C13.3,0.5,0.5,13.3,0.5,29S13.3,57.5,29,57.5S57.5,44.7,57.5,29S44.7,0.5,29,0.5z M42.6,36.5
      C42,38,41,39.2,40,40.2l-1.2,1.2c-1.6,1.2-3.3,2-5.3,2.6c-1.4,0.4-2.8,0.6-4.1,0.6c-0.6,0-1,0-1.6-0.2c-0.8-0.2-1.6-0.2-2.4-0.4
      c-2.4-0.8-4.9-2-6.7-4.1c-0.8-0.8-1.4-1.6-2-2.4c-0.8-1.4-1.6-2.9-2-4.5l-0.2-0.4c-0.2-0.8-0.4-1.8-0.6-2.8c0-0.6-0.2-1.4-0.2-2v-1
      c0-1.4,0.2-2.6,0.6-4.1c1.2-4.9,4.1-9.4,7.9-12.6c2.2-1.8,4.7-3.5,7.7-4.5c0.8-0.2,1.6-0.6,2.2-0.8l1.4-0.4c1.2-0.2,2.4-0.6,3.7-0.6
      c0.6,0,1-0.2,1.4-0.2C38.4,3.8,38,3.8,37.8,4c-3.9,2-6.3,6.3-6.3,10.8v0.8h0.4c2.2,0.4,4.3,1.2,5.9,2.2c1.6,1,2.9,2,3.9,3.5l0.2,0.4
      c0.6,1,1.2,2.2,1.6,3.5c0.4,1.2,0.6,2.6,0.6,4.1v0.6C44.3,32.3,43.7,34.5,42.6,36.5z"
    />
  </g>
);

export const SOCIAL_YOUTUBE = (
  <g>
    <path
      d="M35.5,40v6.7c0,1.4-0.4,2.2-1.2,2.2c-0.4,0-1-0.2-1.4-0.8v-9.6c0.4-0.4,1-0.8,1.4-0.8
      C35.1,38,35.5,38.6,35.5,40z M46.3,40.2v1.4h-2.9v-1.4c0-1.4,0.4-2.2,1.4-2.2S46.3,38.6,46.3,40.2z"
    />
    <path
      d="M51.8,30c-0.2-1.2-0.8-2.2-1.8-3.1c-1-1-2.2-1.4-3.3-1.6c-3.9-0.4-9.8-0.6-17.7-0.6s-13.8,0.2-17.7,0.6
      C9.9,25.5,9,26.1,8,26.8c-0.8,0.8-1.4,2-1.8,3.1c-0.6,2.6-0.8,6.3-0.8,11.2c0,5.1,0.2,8.8,0.8,11.2C6.4,53.6,7,54.7,8,55.5
      c1,0.8,2.2,1.2,3.3,1.4c3.9,0.4,9.8,0.6,17.7,0.6s13.8-0.2,17.7-0.6c1.4,0,2.4-0.6,3.3-1.4c0.8-0.8,1.4-2,1.8-3.1
      c0.6-2.6,0.8-6.3,0.8-11.2C52.6,36.1,52.4,32.3,51.8,30z M18.8,33.1h-3.5v18.1h-3.1V33.1H8.8v-2.9h10
      C18.8,30.2,18.8,33.1,18.8,33.1z M27.4,51.2h-2.6v-1.8c-1.2,1.4-2.4,2-3.3,2c-1,0-1.6-0.4-1.8-1.2c-0.2-0.6-0.2-1.4-0.2-2.4V35.5
      h2.8v12.8c0,0.4,0.2,0.6,0.6,0.6c0.6,0,1.2-0.4,1.8-1.4v-12h2.8C27.4,35.5,27.4,51.2,27.4,51.2z M38.2,46.5c0,1.6,0,2.8-0.2,3.1
      c-0.2,1.2-1,1.8-2.2,1.8c-1,0-2-0.6-2.9-1.8v1.6h-2.8v-21h2.8v6.9c1-1.2,2-1.8,2.9-1.8c1,0,1.8,0.6,2.2,1.8
      c0.2,0.6,0.2,1.6,0.2,3.1V46.5z M49.2,44.1h-5.7v2.8c0,1.4,0.4,2.2,1.4,2.2c0.6,0,1-0.4,1.2-1.2v-2H49v2.2c0,0.6-0.2,1.2-0.6,1.8
      c-0.6,1.2-1.8,1.8-3.3,1.8c-1.4,0-2.6-0.4-3.3-1.6c-0.6-0.8-1-2-1-3.7v-5.5c0-1.8,0.4-2.9,1-3.7c0.8-1,2-1.6,3.3-1.6
      s2.6,0.4,3.3,1.6c0.6,0.8,0.8,2,0.8,3.7C49.2,40.8,49.2,44.1,49.2,44.1z M31.6,7.4c-0.8-1-2-1.6-3.3-1.6c-1.4,0-2.6,0.4-3.3,1.6
      c-0.6,0.8-1,2-1,3.7v5.5c0,1.8,0.4,2.9,1,3.7c0.8,1,2,1.6,3.3,1.6c1.4,0,2.6-0.4,3.3-1.6c0.6-0.8,1-2,1-3.7v-5.5
      C32.5,9.3,32.3,8.2,31.6,7.4z M29.8,17.2c0,1.4-0.6,2.2-1.4,2.2c-1,0-1.4-0.6-1.4-2.2v-6.7c0-1.4,0.4-2.2,1.4-2.2
      c1,0,1.4,0.8,1.4,2.2V17.2z M20.7,0.5h3.3l-3.9,12.8v8.6H17v-8.6c-0.4-1.6-1-3.7-2-6.7c-0.8-2.2-1.6-4.1-2.2-5.9h3.3l2.2,8.5
      C18.4,9.1,20.7,0.5,20.7,0.5z M43.3,6v15.9h-2.9v-1.8c-1.2,1.4-2.2,2-3.3,2c-1,0-1.6-0.4-2-1.2c-0.2-0.6-0.2-1.4-0.2-2.4V6h2.9
      v12.8c0,0.4,0.2,0.8,0.6,0.8c0.6,0,1.2-0.4,1.8-1.4V6H43.3z"
    />
  </g>
);

// ACTIONS

export const ACTION_SEARCH = (
  <g>
    <path
      d="M57 52.2c.7.7.7 1.7 0 2.4L54.6 57c-.7.7-1.7.7-2.4 0L34.9 39.9c-.2-.2-.2-.5-.5-.7-3.6 2.6-7.8 4-12.6
      4C10 43.2.5 33.7.5 21.9S10 .5 21.9.5 43.2 10 43.2 21.9c0 4.7-1.4 9-4 12.6.2 0 .5.2.7.5 0-.1 17.1 17.2 17.1
      17.2zM21.9 7.9C14 7.9 7.6 14.3 7.6 22.1S14 36.3 21.9 36.3s14.2-6.4 14.2-14.2S29.7 7.9 21.9 7.9z"
    />
  </g>
);

export const ACTION_CLOSE = (
  <g>
    <path
      d="M56.8,49.9c1,1,1,2.5,0,3.4l-3.4,3.4c-1,1-2.5,1-3.4,0L29,35.9L8.1,56.8c-1,1-2.5,1-3.4,0l-3.4-3.4
      c-1-1-1-2.5,0-3.4L22.1,29L1.2,8.1c-1-1-1-2.5,0-3.4l3.4-3.4c1-1,2.5-1,3.4,0L29,22.1L49.9,1.2c1-1,2.5-1,3.4,0l3.4,3.4
      c1,1,1,2.5,0,3.4L35.9,29L56.8,49.9z"
    />
  </g>
);

