import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import * as Icons from './Icons';

const Icon = ({ id, icon, theme, size, style, className }) => {
  const styles = require('./Icon.module.scss');

  return (
    <div id={id} className={!className ? styles[theme] : className} style={{ width: size, height: size, verticalAlign: 'middle', ...style }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 58 58"
        style={{ width: size, height: size, verticalAlign: 'middle' }}
        className={styles.Icon}
      >
        {Icons[icon]}
      </svg>
    </div>
  );
};

Icon.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  theme: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string
};

export default Icon;
